import { useEffect, useRef, useState } from "react";
import AnswerView from "./AnswerView";

const AnzanText = (props: { problems: number[], digit: number, problemsCount: number, displaySeconds: number}) => { 
    const [index, setIndex] = useState(0)
    const [count, setCount] = useState(0)
    const [stateValue, setStateValue] = useState("start")


    const displayNumberMS: number = props.displaySeconds*1000
    const hideNumbersMS: number = 500
    const displayStartMS: number = 1000
    const waitStartMS: number = 1000

    const indexRef = useRef(0);
    indexRef.current = index;  // Update the boxed value on render

    const view = () => { 
        if (stateValue == "displaying") { 
            return <h1 className="Anzan-Text">{props.problems[index]}</h1>
        } else if (stateValue == "start") { 
            return <div>スタート！</div>
        } else if (stateValue == "finish_displaying") { 
            return <AnswerView problems={props.problems} digit={props.digit} problemsCount={props.problemsCount} displaySeconds={props.displaySeconds} />
        } else if ((stateValue == "hiding") || (stateValue == "waiting_start")){ 
            return <div></div>
        }
    }

    function properInterval():number { 
        if (stateValue == "displaying") { 
            return displayNumberMS
        } else if (stateValue == "hiding") { 
            return hideNumbersMS
        } else if (stateValue == "start") { 
            return displayStartMS
        } else if (stateValue == "waiting_start") { 
            return waitStartMS
        } else { 
            return 0
        }
    }

    useEffect(() => { 
        const timer = setTimeout(() => {
            if (stateValue == "start") { 
                setStateValue("waiting_start")
            } else if (stateValue == "waiting_start") { 
                setStateValue("displaying")
            } else if (stateValue == "displaying") { 
                setStateValue("hiding")
            } else if (stateValue == "hiding") { 
                setStateValue("displaying")
                setIndex(index + 1)
                if (indexRef.current + 1 == props.problems.length) { 
                    clearTimeout(timer)
                    setStateValue("finish_displaying")
                }
            }
            setCount(count + 1)
        }, properInterval());        
        return () => {
            clearTimeout(timer)
          };

    }, [count])
    return (
    <div> 
        {view()}
    </div>
    )
}

export default AnzanText

const PrepareProblems = (digit: number, problemsCount: number) => { 
    var rangeArray: number[] = []
    if (digit == 1) { 
        rangeArray = range(1,9)
    } if (digit == 2) { 
        rangeArray = range(10, 99)
    } if (digit == 3) { 
        rangeArray = range(100, 999)
    } if (digit == 4) { 
        rangeArray = range(1000, 9999)
    } if (digit == 5) { 
        rangeArray = range(10000, 99999)
    }

    const problemsCountArray = range(0, problemsCount - 1)
    const problemsArray = problemsCountArray.map ((_) => { return rangeArray[Math.floor(Math.random() * rangeArray.length)] })
    return problemsArray
}

export default PrepareProblems

export const range =
  (start: number, end: number) => Array.from({length: (end - start + 1)}, (v, k) => k + start);
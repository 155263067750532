import userEvent from "@testing-library/user-event";
import { count, countReset } from "console";
import { stringify } from "querystring";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { Link } from 'react-router-dom'

const PlayGround = () => { 

    const [numString, setNumString] = useState("")

    const touchHandler = (num: string) => { 
        setNumString(numString + num)
    }

    const clearHandler = () => { 
        setNumString("")
    }

    const enterHandler = () => { 
        const answeredNum = Number(setNumString)
        
    }

    return (
    <div className="App"> 
        <header className="App-header">
            <h1>
                {numString}
            </h1>
            <div className="Keyboard-Container">
                <Row >
                    <Col className="Numpad-Item" onClick={() => touchHandler("7")}>7</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("8")}>8</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("9")}>9</Col>
                </Row>

                <Row>
                    <Col className="Numpad-Item" onClick={() => touchHandler("4")}>4</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("5")}>5</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("6")}>6</Col>
                </Row>

                <Row>
                    <Col className="Numpad-Item" onClick={() => touchHandler("1")}>1</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("2")}>2</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("3")}>3</Col>
                </Row>
                <Row>
                    <Col className="Numpad-Item" onClick={() => clearHandler()}>clear</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("0")}>0</Col>
                    <Col className="Numpad-Item"><Link to="/flash-anzan-result" state={{ problems: [1,2,3], answeredNum: Number(numString)}} style={{color:"white", textDecoration:'none'}}>enter</Link></Col>
                </Row>
            </div>
        </header>
    </div>
    )
}

export default PlayGround
import { useEffect, useRef, useState } from 'react';
import './App.css';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import ReactGA from "react-ga4";


function App() {

  class Config { 
    digit: number;
    problemsCount: number;

    constructor(digit: number, problemsCount: number) { 
      this.digit = digit;
      this.problemsCount = problemsCount;
    }
  }

  const digitMin: number = 1
  const digitMax: number = 5
  const problemsCountMin: number = 2
  const problemsCountMax: number = 20
  const displaySecondsMin: number = 0.1
  const displaySecondsMax: number = 5.0

  const [digit, setDigit] = useState(Number(localStorage.getItem('digit')) == 0 ? digitMin : Number(localStorage.getItem('digit')))
  const [problemsCount, setProblemsCount] = useState(Number(localStorage.getItem('problems_count')) == 0 ? problemsCountMin : Number(localStorage.getItem('problems_count')))
  const [displaySeconds, setDisplaySeconds] = useState(Number(localStorage.getItem('display_seconds')) == 0 ? displaySecondsMin : Number(localStorage.getItem('display_seconds')))

  const handleDigitChange = (operation: string) => { 
    if (operation == "subtract") { 
      setDigit(digit == digitMin ? digitMin : digit - 1)
    } else { 
      setDigit(digit == digitMax ? digitMax : digit + 1)
    }
  }

  const handleProblemsCountChange = (operation: string) => { 
    if (operation == "subtract") { 
      setProblemsCount(problemsCount == problemsCountMin ? problemsCountMin : problemsCount - 1)
    } else { 
      setProblemsCount(problemsCount == problemsCountMax ? problemsCountMax : problemsCount + 1)   
    }
  }

  const handleDisplaySecondsChange = (operation: string) => { 
    if (operation == "subtract") { 
      setDisplaySeconds(displaySeconds == displaySecondsMin ? displaySecondsMin : ((displaySeconds * 10) - (0.1*10)) / 10)
    } else { 
      setDisplaySeconds(displaySeconds == displaySecondsMax ? displaySecondsMax : ((displaySeconds * 10) + (0.1 * 10)) / 10)
    }
  }

  useEffect(() => { 
    localStorage.setItem('digit', `${digit}`)
  }, [digit])

  useEffect(() => { 
    localStorage.setItem('problems_count', `${problemsCount}`)
  })

  useEffect(() => { 
    localStorage.setItem('display_seconds', `${displaySeconds}`)
  })

  useEffect(() => { 
    ReactGA.initialize("G-6KGXVW47DL");
    ReactGA.event("Impression_Home")
  }, [])

  return (
    <div className="App">
      <head>
        <title>フラッシュ暗算 drillio - 脳トレ・数学・算数のドリル</title>
        <meta name="description" content='暗算ができないとお悩みの方、そろばんの練習をしたい方ピッタリ。今すぐ無料でフラッシュ暗算にチャレンジしてみよう。子供から大人まで暗算の練習が楽しめます。'/>
      </head>
      <header className="App-header">
        <h1>フラッシュ暗算 drillio</h1>
        <h2 className='Subtitle'>無料で暗算の練習をしてみよう</h2>
        <div className='Setting-Container Container'>
          <Stack direction="horizontal" gap={5} className='Setting-Component'>
            <div className='Setting-Title'>桁数</div>
            <button className='ms-auto Setting-Button' onClick={()=>handleDigitChange("subtract")}>-</button>
            <div className='Setting-Value'>{digit}</div>
            <button className='Setting-Button' onClick={()=>handleDigitChange("add")}>+</button>
          </Stack>
          <Stack direction="horizontal" gap={5} className='Setting-Component'>
            口数
            <button className='ms-auto Setting-Button' onClick={()=>handleProblemsCountChange("subtract")}>-</button>
            <div className='Setting-Value'>{problemsCount}</div>
            <button className='Setting-Button' onClick={()=>handleProblemsCountChange("add")}>+</button>
          </Stack>
          <Stack direction="horizontal" gap={5} className='Setting-Component'>
            表示秒数
            <button className='ms-auto Setting-Button' onClick={()=>handleDisplaySecondsChange("subtract")}>-</button>
            <div className='Setting-Value'>{displaySeconds}</div>
            <button className='Setting-Button' onClick={()=>handleDisplaySecondsChange("add")}>+</button>
          </Stack>
          <Link to="/flash-anzan" state={{ digit: digit, problemsCount: problemsCount, displaySeconds: displaySeconds}}>
          <h3>
            <button className='Setting-Start-Button'>
                フラッシュ暗算を開始
            </button>
          </h3>
          </Link>
          {/* <Link to="/playground">
            <p>
              Playgroundへ
            </p>
          </Link> */}

        </div>
        <div className='Container App-Store'>
          iOSアプリで学習記録を貯めよう！<br />
          <a href="https://apps.apple.com/jp/app/drillio-%E7%B6%99%E7%B6%9A%E3%81%A7%E3%81%8D%E3%82%8B%E6%9A%97%E7%AE%97%E8%84%B3%E3%83%88%E3%83%AC%E3%82%A2%E3%83%97%E3%83%AA/id6444734622?platform=iphone"><img src={`${process.env.PUBLIC_URL}/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg`} alt="Logo" /></a>
        </div>
      </header>
    </div>
  );
}

export default App;

import TextFileViewer from "../UseCase/TextViewer"

const AppAds = () => { 
    return (
    <div className="App"> 
        <TextFileViewer filePath="/app-ads.txt"  />
    </div>
    )
}

export default AppAds
import { useEffect, useState } from "react";

interface TextFileViewerProps {
    filePath: string;
  }
  
  const TextFileViewer: React.FC<TextFileViewerProps> = ({ filePath }) => {
    const [fileContent, setFileContent] = useState('');
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(filePath);
          const text = await response.text();
          setFileContent(text);
        } catch (error) {
          console.error('Error fetching file:', error);
        }
      };
  
      fetchData();
    }, [filePath]);
  
    return (
      <div>
        <pre>{fileContent}</pre>
      </div>
    );
  };
  
  export default TextFileViewer;
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

interface AnzanResultState {
    problems: number[],
    answeredNum: number,
    digit: number,
    problemsCount: number,
    displaySeconds: number,
    elapsedTimeMS: number
}

const AnzanResult = () => { 
    const location = useLocation();
    const {problems, answeredNum, digit, problemsCount, displaySeconds, elapsedTimeMS} = location.state as AnzanResultState
    const answer = problems.reduce(function(a, x){return a + x;});

    const evaluation = () => { 
        if (answeredNum == answer) { 
            return "正解！！"
        } else { 
            return "不正解・・・！"
        }
    }


    useEffect(() => { 
        ReactGA.initialize("G-6KGXVW47DL");
        ReactGA.event("Impression_Anzan_Result")
    }, [])

    return (
        <div className="App"> 
            <header className="App-header">
                <div className="Container">
                    <h1 className="Result-Title">
                        {evaluation()}
                    </h1>
                    {digit}桁 {problemsCount}口の計算 | 表示時間は{displaySeconds}秒
                    <div>
                        <Row className="Result-Details-Row Bottom-Border Top-Border Top-Border-Radius">
                            <Col className="Result-Details-Key">経過時間</Col>
                            <Col className="Result-Details-Value">{elapsedTimeMS / 1000}秒</Col>
                        </Row>

                        <Row className="Result-Details-Row Bottom-Border">
                            <Col className="Result-Details-Key">あなたの答え</Col>
                            <Col className="Result-Details-Value">{answeredNum}</Col>
                        </Row>
                        <Row className="Result-Details-Row Bottom-Border">
                            <Col className="Result-Details-Key">正解</Col>
                            <Col className="Result-Details-Value">{answer}</Col>
                        </Row>
                        <Row className="Result-Details-Row Bottom-Border Bottom-Border-Radius">
                            <Col className="Result-Details-Key">問題</Col> 
                            <Col className="Result-Details-Value">{problems.join("+")}</Col>
                        </Row>
                    </div>
                    <div className="Result-Button-Container">
                        <Link to="/" style={{color:"white", textDecoration:'none'}}><button className="Result-Button">ホーム</button></Link>
                        <Link to="/flash-anzan" state={{ digit: digit, problemsCount: problemsCount, displaySeconds: displaySeconds}} style={{color:"white", textDecoration:'none'}}><button className="Result-Button">リトライ</button></Link>
                    </div>
                </div>
                <div className='Container App-Store'>
                    iOSアプリで学習記録を貯めよう！<br />
                    <a href="https://apps.apple.com/jp/app/drillio-%E7%B6%99%E7%B6%9A%E3%81%A7%E3%81%8D%E3%82%8B%E6%9A%97%E7%AE%97%E8%84%B3%E3%83%88%E3%83%AC%E3%82%A2%E3%83%97%E3%83%AA/id6444734622?platform=iphone"><img src={`${process.env.PUBLIC_URL}/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg`} alt="Logo" /></a>
                </div>
            </header>
        </div>
        )
}

export default AnzanResult
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom'

const AnswerView = (props: {problems: number[], digit: number, problemsCount: number, displaySeconds: number}) => { 

    const [numString, setNumString] = useState("")
    const [shownDate, setShownDate] = useState(new Date())

    const touchHandler = (num: string) => { 
        setNumString(numString + num)
    }

    const clearHandler = () => { 
        setNumString("")
    }

    useEffect(() => { 
        setShownDate(new Date())
    }, [])

    return (
        <div className="In-Container">
            <div className="Number-Container">
                <h1 className="Answer-Number">
                    {numString}
                </h1>
            </div>
            <div className="Keyboard-Container">
                <Row >
                    <Col className="Numpad-Item" onClick={() => touchHandler("7")}>7</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("8")}>8</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("9")}>9</Col>
                </Row>

                <Row>
                    <Col className="Numpad-Item" onClick={() => touchHandler("4")}>4</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("5")}>5</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("6")}>6</Col>
                </Row>

                <Row>
                    <Col className="Numpad-Item" onClick={() => touchHandler("1")}>1</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("2")}>2</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("3")}>3</Col>
                </Row>
                <Row>
                    <Col className="Numpad-Item" onClick={() => clearHandler()}>clear</Col>
                    <Col className="Numpad-Item" onClick={() => touchHandler("0")}>0</Col>
                    <Col className="Numpad-Item"><Link to="/flash-anzan-result" state={{ problems: props.problems, answeredNum: Number(numString), digit: props.digit, problemsCount: props.problemsCount, displaySeconds: props.displaySeconds, elapsedTimeMS: (new Date().getTime() - shownDate.getTime())}} style={{color:"white", textDecoration:'none'}}>enter</Link></Col>
                </Row>
            </div>
    </div>
    )
}

export default AnswerView
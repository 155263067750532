import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Z_ASCII } from "zlib";
import AnzanText from "./Components/AnzanText";
import PrepareProblems from "./UseCase/PrepareProblems";
import ReactGA from "react-ga4";


interface State {
    digit: number,
    problemsCount: number,
    displaySeconds: number
}

const FlashAnzan = () => { 
    const location = useLocation();
    const {digit, problemsCount, displaySeconds} = location.state as State

    const problems = PrepareProblems(digit, problemsCount)

    var isShowing: boolean = false

    useEffect(() => { 
        ReactGA.initialize("G-6KGXVW47DL");
        ReactGA.event("Impression_Flash_Anzan")
    }, [])

    return <div className="App">
            <header className="App-header">
                <div className="Container">
                    <AnzanText problems={problems} digit={digit} problemsCount={problemsCount} displaySeconds={displaySeconds} />
                </div>
            </header>
        </div>
}

export default FlashAnzan